
  .control {
    margin-bottom: 0.5rem;
  }
  
  .control label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    /* margin-bottom: 0.5rem; */
  }
  
  .control input,
  .control textarea {
    display: block;
    font: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
  }

  